<template>
  <main class="ErrorView">
    <header>
      <h1>Error {{ error }}</h1>
    </header>

    <p class="lead mb-3">Sorry, there is a problem…</p>

    <p v-if="error !== 'network'">
      <router-link class="AppSidebar__item" :to="{ name: 'dashboard' }">
        Back to Dashboard
      </router-link>
    </p>
  </main>
</template>

<script>
  export default {
    props: {
      error: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'
</style>
